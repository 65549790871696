/* OrdersView CSS */

#page-orders {
    width:auto !important;
    margin:auto 30px !important;
}

/* Make Filters Row look like semantic-ui */
.filters-row input,
.filters-row .ui.selection.dropdown {
    padding:9px 8px;
    color:black;
    border: 1px solid rgb(199, 213, 216);
    border-radius:4px;
    font-family: 'Open Sans', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;
    font-size:13px;
    font-weight:300;
    line-height:16px;
    width: 165px;
    min-width: 165px;
}
.filters-row input:focus,
.filters-row .ui.selection.dropdown:focus {
    border-color:rgba(109, 150, 178, 0.7)
}
.filters-row .DateRangePicker input {
    border:none;
    width:90px;
    min-width:90px;
}
.filters-row .DateRangePicker button {
    margin: -2px 1px 0 5px;
}
.filters-row .DateRangePicker .DateRangePickerInput {
    border: 1px solid rgb(199, 213, 216);
    border-radius:4px;
}
.filters-row .DateRangePicker .DateRangePickerInput:focus {
    border-color:rgba(109, 150, 178, 0.7)
}
.filters-row .DateInput {
    width:90px;
}
.filters-row .ui.selection.dropdown > .dropdown.icon{
    padding-right:9px;
}
th .more-info-icon {
    margin: 0 0 -5px 5px;
}
