/* Styles for ShopClosedDatesCalendar.jsx */
.ui.list {
    padding-left:15px !important;
}
i.icon.tomato::before {
    background-color:tomato !important;
}
i.icon.indianred::before {
    background-color:indianred !important;
    opacity:0.6;
}
i.icon.lightgreen::before {
    background-color:lightgreen !important;
}
.calendar-instructions {
    text-align:center;
}
