/* Styles for ShopView.jsx */
.shop-menu-view-wrapper button.ui.orange {
    margin:25px auto;
}
.shop-menu-view-wrapper button.ui.big.orange {
    width:200px;
}
.shop-menu-view-wrapper .buttons-wrapper {
    text-align:center;
}
.shop-menu-view-wrapper p {
    text-align:center;
    margin:50px auto !important;
}
.shop-menu-view-wrapper header {
    margin-bottom:30px;
}
.shop-menu-view-wrapper .documentFirstHeading::before {
    border-bottom: 3px solid #db7b00 !important;
}
