/* Styles for Articles.jsx component */
.ui.tiny.modal {
  margin: auto !important;
}

.top-menu.menu {
  margin-bottom: 25px !important;
}

.top-menu-searchbox .ui.icon.input input {
  padding-left: 14px;
}

.ui.card img {
  max-height: 400px;
}

.ui.cards > .card > .content {
  text-align: center !important;
}

.ui.cards > .card > .extra.content {
  text-align: left !important;
}

#page-contents .ui.card > .extra.publish-dates {
  min-height: 70px !important;
  padding: 0;
  text-align: center !important;
}

#page-contents .ui.card > .extra.publish-dates > * {
  padding: 8px;
}

.product-expired-banner {
  width: 100%;
  height: 100%;
  background-color: red;
  color: white !important;
  text-transform: uppercase;
}

.contents-pagination {
  margin-top: 35px;
}

h5 {
  margin-bottom: 5px;
  font-size: 0.8rem !important;
  text-transform: uppercase;
}

.ui.card.private img {
  opacity: 0.4;
}

.ui.card.expired {
  opacity: 0.4;
}

.date-range {
  margin-bottom: 10px;
}
