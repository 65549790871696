/* This is Style file for OrderView.jsx */

#page-order #content-core {
    display:grid;
    gap:1.2rem;
    grid-template-areas: "header"
                         "order-config"
                         "delivery-address"
                         "buyer-info"
                         "recipient-info"
                         "gift-message"
                         "shop-message"
                         "products"
                         "order-status"
                         "confirm-button"
                         "timeline";
}

@media (min-width: 768px) {
    #page-order #content-core {
        grid-template-areas: "header header"
                             "order-config delivery-address"
                             "buyer-info recipient-info"
                             "gift-message shop-message"
                             "products products"
                             "order-status order-status"
                             "confirm-button confirm-button"
                             "timeline timeline";
    }
}

#page-order #content-core h1.header {
    grid-area:header;
}
#page-order #content-core .order-configuration {
    grid-area: order-config;
}
#page-order #content-core .buyer-info {
    grid-area: buyer-info;
}
#page-order #content-core .recipient-info {
    grid-area: recipient-info;
}
#page-order #content-core .gift-message {
    grid-area: gift-message;
}
#page-order #content-core .shop-message {
    grid-area: shop-message;
}
#page-order #content-core .products {
    grid-area: products;
}
#page-order #content-core .timeline {
    grid-area: timeline;
}



.order-block .header + * {
    padding:10px;
}

#page-order #content-core button.edit-btn{
    float:right;
}
#page-order #content-core button svg{
    height:20px !important;
}
#page-order #content-core .header {
    justify-content: flex-start;
    align-items: center;
}
#page-order #content-core .shop-message button,
#page-order #content-core .gift-message button {
    float:right;
}
#page-order .ui.steps {
    flex-wrap:wrap;
}
