/* Styles for Prices.jsx */
.prices-menu.menu .item:first-child,
.prices-menu.menu .item:last-child {
    flex-basis:30%;
}
.prices-menu.menu .item:nth-child(2) {
    flex-basis:40%;
}
.ui.button.filter-button {
    margin-left:8px;
    vertical-align:middle;
}
button.orange .close {
    color:white;
}
.items-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
}

.item-name {
  flex-basis: 100%;
}

.items-wrapper .item-image img {
  max-width: 80px;
  height: auto;
}
/* Price tables */
.prices-table {
  color: #666;
}
.shop-menu-view-wrapper .prices-menu .help {
    margin:10px auto !important;
    text-align:left;
    font-size:0.9rem;
}
.group-option-name {
  font-weight: bold;
  text-transform: uppercase;
}

.price-wrapper {
  display: grid;
  align-items: center;
  margin: 5px auto;
  gap: 1rem;
  grid-template-columns: 1fr 80px;
}

.price-wrapper input {
  width: 80px;
  padding: 5px 0 3px 5px !important;
}
