
h3{
    text-align:center;
}
table.ui.table.month-calendar {
    text-align:center;
    margin-bottom: 40px !important;
}
.month-calendar td {
    color:lightgrey;
}
.month-calendar .selectable,
.ui.table.month-calendar tbody tr td.selectable:hover {
    cursor: pointer;
    background-color:lightgreen!important;
    color:rgba(0, 0, 0, 0.87);
}
.month-calendar td[class*="variable"],
.ui.table.month-calendar tbody tr td[class*="variable"].selectable:hover{
    background-color:tomato !important;
}
.month-calendar :not(td.selectable) {
    background-color:transparent !important;
}
.month-calendar td[class*="fixed"],
.ui.table.month-calendar tbody tr td[class*="fixed"].selectable:hover{
    background-color:indianred !important;
    color:ghostwhite;
    opacity:0.6;
}
